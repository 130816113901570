import { WaveButtonStyles, WaveButtonStyles2 } from "./WaveButton.styles";

const WaveButton = ({ children, path, click }) => {
  if(click){
    return <WaveButtonStyles2 onClick={click}>{children}</WaveButtonStyles2>;
  }else {
    return <WaveButtonStyles to={path}>{children}</WaveButtonStyles>;
  } 
  
};
export default WaveButton;
