import { useState } from "react";
import Modal from "../../UICs/Modal/Modal.uic";
import "./ParticipantsCard.layout.scss";

export const ParticipantsCard = ({ name, points, statut, avatar, secret }) => {
  const [showModal, setShowModal] = useState(false);

  const onSmallImgClick = () => {
    setShowModal(true);
  };

  return (
    <>
      <div
        className='participant-card'
        onClick={() => {
          onSmallImgClick();
        }}
      >
        <div className='profile'>
          <img className='grand-overview' src={avatar} alt='Grand profile' />
        </div>
        {statut === false && <div className='cancel-filter'></div>}
        <div className='detail-view'>
          <div className='visible-block'>
            <img className='profile-img' src={avatar} alt='Petit profile' />
            <div className='details'>
              <h6 className='nom'>{name} </h6>
              <span className='pseudo'>{""}</span>
              <div className='points'>
                <span
                  className='status'
                  style={{ color: statut === true ? "#28a745" : "#dc3545" }}
                >
                  {statut === true ? "actif" : "éliminé"}
                </span>
                <span className='point'>{points}</span>
              </div>
            </div>
          </div>
          {statut === false && (
            <div className='hidden-block'>
              <h6>Secret</h6>
              {statut === false ? (
                <span className='secret'>{secret}</span>
              ) : (
                <span className='hiding-secret'>
                  Nothing to see here. Find yourself!
                </span>
              )}
            </div>
          )}
        </div>
      </div>
      {showModal && (
        <Modal onClick={e => setShowModal(false)}>
          <div className='participant-modal'>
            <span
              className='modal-status'
              style={{ color: statut === true ? "#28a745" : "#dc3545" }}
            >
              {statut === true ? "actif" : "éliminé"}
            </span>
            <img className='image-on-modal' src={avatar} alt={`avatar`} />
            {!statut ? (
              <span className='modal-secret'>{secret}</span>
            ) : (
              <span className='hiding-secret'></span>
            )}
          </div>
        </Modal>
      )}
    </>
  );
};
