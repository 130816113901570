import React, { useState } from "react";
import { useAuth } from "../../auth/AuthContext";
import { Redirect } from "react-router-dom";
import "./Login.style.scss";
import { MingcuteEyeLine } from "../../components/mingCuteEyeLine/mingCuteEyeLine";
import { MingcuteEyeCloseLine } from "../../components/mingCuteEyeCloseLine/mingCuteEyeCloseLine";

const LoginPage = () => {
  const [phoneNumber, setPhoneNumber] = useState("");
  const [password, setPassword] = useState("");

  const [isShow, setIsShow] = useState(false);
  // const [isloading, setIsloading] = useState(true);
  const { user, login } = useAuth();

  if (user && user.isConnect === 1) {
    return <Redirect to='/home' />;
  }

  const handleLogin = e => {
    // setIsloading(true);
    e.preventDefault();
    login(phoneNumber, password);
    // setIsloading(false);
  };

  const handlePassword = event => {
    setIsShow(event);
  };

  return (
    <div className='login-page'>
      <div className='fs-logo'>
        <img
          src={process.env.PUBLIC_URL + "/assets/images/gos.png"}
          className='logo-title'
          alt='hey'
        />
      </div>
      <div className='login-box'>
        <h2>Connexion</h2>
        <form onSubmit={handleLogin}>
          <div>
            <input
              placeholder='Numéro de téléphone'
              type='text'
              value={phoneNumber}
              onChange={e => setPhoneNumber(e.target.value)}
              required
            />
            <div className='form-control'>
              <input
                placeholder='Mot de passe'
                type={isShow ? "text" : "password"}
                value={password}
                onChange={e => setPassword(e.target.value)}
                required
              />
              <div className='password-show'>
                {isShow ? (
                  <button type='button' onClick={() => handlePassword(false)}>
                    <MingcuteEyeCloseLine />
                  </button>
                ) : (
                  <button type='button' onClick={() => handlePassword(true)}>
                    <MingcuteEyeLine />
                  </button>
                )}
              </div>
            </div>
            <button type='submit'>Se connecter</button>
          </div>
        </form>
        {/* {isloading && <div className='loading-mask'></div>} */}
      </div>
    </div>
  );
};

export default LoginPage;
