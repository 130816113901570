import { Link } from "react-router-dom";
import styled from "styled-components";

const borderColor = "#d4166d";

export const WaveButtonStyles = styled(Link)`
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 1em 1.5em;
  border: none;
  background-color: transparent;
  cursor: pointer;
  outline: none;
  font-size: 1 rem;
  font-weight: bold;
  margin: 0 0.6em;
  text-decoration: none;
  color: #fff;

  &::after,
  &::before {
    content: "";
    display: block;
    position: absolute;
    width: 20%;
    height: 20%;
    border: 3px solid;
    transition: all 0.6s ease;
    border-radius: 2px;
  }

  &::after {
    bottom: 0;
    right: 0;
    border-top-color: transparent;
    border-left-color: transparent;
    border-bottom-color: ${borderColor};
    border-right-color: ${borderColor};
  }

  &::before {
    top: 0;
    left: 0;
    border-bottom-color: transparent;
    border-right-color: transparent;
    border-top-color: ${borderColor};
    border-left-color: ${borderColor};
  }

  &:hover:after,
  &:hover:before {
    border-bottom-color: ${borderColor};
    border-right-color: ${borderColor};
    border-top-color: ${borderColor};
    border-left-color: ${borderColor};
    width: 100%;
    height: 100%;
  }

  /* Smartphones (portrait) ----------- */
  @media only screen and (max-width: 1100px) {
    /* Styles */
    font-size: 0.865rem;
    padding: 0.75em 1em;
  }

  /* Smartphones (portrait) ----------- */
  @media only screen and (max-width: 980px) {
    /* Styles */
    font-size: 0.8rem;
    padding: 0.55em 0.8em;
  }

  @media only screen and (max-width: 720px) {
    /* Styles */
    font-size: 0.8rem;

    &::after,
    &::before {
      border: 2px solid;
    }
  }

  @media only screen and (max-width: 480px) {
    /* Styles */
    font-size: 0.7rem;

    &::after,
    &::before {
      border: 2px solid;
    }
  }

  @media only screen and (max-width: 400px) {
    /* Styles */
    font-size: 0.7rem;
    padding: 0.45em 0.7em;

    &::after,
    &::before {
      border: 1px solid;
    }
  }
`;

export const WaveButtonStyles2 = styled.div`
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 1em 1.5em;
  border: none;
  background-color: transparent;
  cursor: pointer;
  outline: none;
  font-size: 1 rem;
  font-weight: bold;
  margin: 0 0.6em;
  text-decoration: none;
  color: #fff;

  &::after,
  &::before {
    content: "";
    display: block;
    position: absolute;
    width: 20%;
    height: 20%;
    border: 3px solid;
    transition: all 0.6s ease;
    border-radius: 2px;
  }

  &::after {
    bottom: 0;
    right: 0;
    border-top-color: transparent;
    border-left-color: transparent;
    border-bottom-color: ${borderColor};
    border-right-color: ${borderColor};
  }

  &::before {
    top: 0;
    left: 0;
    border-bottom-color: transparent;
    border-right-color: transparent;
    border-top-color: ${borderColor};
    border-left-color: ${borderColor};
  }

  &:hover:after,
  &:hover:before {
    border-bottom-color: ${borderColor};
    border-right-color: ${borderColor};
    border-top-color: ${borderColor};
    border-left-color: ${borderColor};
    width: 100%;
    height: 100%;
  }

  /* Smartphones (portrait) ----------- */
  @media only screen and (max-width: 1100px) {
    /* Styles */
    font-size: 0.865rem;
    padding: 0.75em 1em;
  }

  /* Smartphones (portrait) ----------- */
  @media only screen and (max-width: 980px) {
    /* Styles */
    font-size: 0.8rem;
    padding: 0.55em 0.8em;
  }

  @media only screen and (max-width: 720px) {
    /* Styles */
    font-size: 0.8rem;

    &::after,
    &::before {
      border: 2px solid;
    }
  }

  @media only screen and (max-width: 480px) {
    /* Styles */
    font-size: 0.7rem;

    &::after,
    &::before {
      border: 2px solid;
    }
  }

  @media only screen and (max-width: 400px) {
    /* Styles */
    font-size: 0.7rem;
    padding: 0.45em 0.7em;

    &::after,
    &::before {
      border: 1px solid;
    }
  }
`;
