import "./App.scss";
import HomePage from "../Pages/HomePage/HomePage";
import { Switch, Route, Redirect } from "react-router-dom";
import { routes } from "../config/routes";
import RulesPage from "../Pages/RulesPage/RulesPage";
import ParticipantsPage from "../Pages/ParticipantsPage/ParticipantsPage";
import { AuthProvider, useAuth } from "../auth/AuthContext";
import LoginPage from "../Pages/Login/LoginPage";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

const ProtectedRoute = ({ children, ...rest }) => {
  const { user } = useAuth();

  return (
    <Route
      {...rest}
      render={({ location }) =>
        user && user.isConnect === 1 ? (
          children
        ) : (
          <Redirect
            to={{ pathname: routes.loginpage, state: { from: location } }}
          />
        )
      }
    />
  );
};

function App() {
  return (
    <AuthProvider>
      <div className='App'>
        <Switch>
          <Route path={routes.loginpage} component={LoginPage} exact />
          <ProtectedRoute path={routes.homepage} exact>
            <HomePage />
          </ProtectedRoute>
          <ProtectedRoute path={routes.participants} exact>
            <ParticipantsPage />
          </ProtectedRoute>
          <ProtectedRoute path={routes.rulespage} exact>
            <RulesPage />
          </ProtectedRoute>
          {/* Redirection par défaut vers /login */}
          <Redirect from='*' to={routes.loginpage} />
        </Switch>
        <ToastContainer
          position='top-center'
          autoClose={5000}
          hideProgressBar={false}
          newestOnTop={false}
          closeOnClick
          rtl={false}
          pauseOnFocusLoss
          draggable
          pauseOnHover
          theme='colored'
        />
      </div>
    </AuthProvider>
  );
}

export default App;
