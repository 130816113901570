import { EmojioneV1EmptyNotePad } from "../../emptyNotePad/EmptyNotePad";
import "./empty.style.scss";

const NomineEmpty = ({ title }) => {
  return (
    <div className='empty-container'>
      <EmojioneV1EmptyNotePad />
      <h2>Oups !</h2>
      <p>{title}</p>
    </div>
  );
};

export default NomineEmpty;
