import axios from "axios";
import { from } from "rxjs";
import { APP_API_URL } from "../config";



class ApiCall {
    static ApiUrl = APP_API_URL;

    
    static getNomines(method) {
        const apiToCall = `${this.ApiUrl}${method}`;
        return from(axios.get(apiToCall));
    }

    static getParticipants(method) {
        const apiToCall = `${this.ApiUrl}${method}`;
        return from(axios.get(apiToCall));
    }

    static getVoteData(method) {
        const apiToCall = `${this.ApiUrl}${method}`;
        return from(axios.post(apiToCall));
    }

    static post(method , data , params) {
        const apiToCall = `${this.ApiUrl}${method}`;
        return  from(axios.post(apiToCall, data));
    }

    static postVoted(method , data , params) {
        const apiToCall = `${this.ApiUrl}${method}`;
        return  from(axios.put(apiToCall, data));
    }
}
export {ApiCall}