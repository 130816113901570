import React from "react";

export function EmojioneV1EmptyNotePad(props) {
  return (
    <svg
      xmlns='http://www.w3.org/2000/svg'
      width='4em'
      height='4em'
      viewBox='0 0 64 64'
      {...props}
    >
      <path
        fill='#fff200'
        d='m3.988 48.46l47.452 6.62L59.979 9L16.898 3.179z'
      ></path>
      <path
        fill='#e8d807'
        d='m3.988 48.46l3.562 5.531l48.14 7.712l-4.246-6.623z'
      ></path>
      <path
        fill='#eddc0a'
        d='M14.406 1S4.862 45.37 2.842 46.678c-2.02 1.312 47.902 7.313 47.902 7.313s9.133-43.979 9.235-44.989s-45.573-8-45.573-8'
      ></path>
      <path
        fill='#ad9a28'
        d='M15.507 2.436s-11.263 40.03-13.28 41.34C.217 45.088 51 52.552 51 52.552s8.429-42.4 8.526-43.41c.103-1.01-44.02-6.714-44.02-6.714'
      ></path>
      <path
        fill='#dbbf33'
        d='m59.979 9l3.873 3.798l-8.166 48.905l-4.246-6.623z'
      ></path>
      <path
        fill='#e8d807'
        d='M14.406 1S2.076 38.476.06 39.784c-2.02 1.312 47.695 13.186 50.22 10.728s9.601-40.499 9.703-41.509s-45.573-8-45.573-8'
      ></path>
      <path
        fill='#fff200'
        d='M14.406 1S3.567 34.03 1.549 35.35c-2.02 1.312 37.879 17.09 42.07 13.211c1.813-1.677 6.04-9.03 8.874-15.934c4.802-11.696 7.422-23 7.482-23.623c.102-1.01-45.573-8-45.573-8'
      ></path>
    </svg>
  );
}
