import Slider from "react-slick";
import WaveButton from "../../components/UICs/WaveButton/WaveButton.uic";
import { routes } from "../../config/routes";
import "./RulesPage.scss";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import Modal from "../../components/UICs/Modal/Modal.uic";
import { useState } from "react";
import Footer from "../../components/layouts/Footer/Footer";

const RulesPage = () => {
  const [showModal, setShowModal] = useState(false);
  const [indice, setIndice] = useState(1);

  const sliderSettings = {
    dots: true,
    infinte: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    initialSlide: 0,
    adaptiveHeight: true,
    autoplay: false,
    autoplaySpeed: 3000,
    pauseOnHover: true,
    focusOnSelect: true,
    responsive: [
      {
        breakpoint: 660,
        settings: {
          centerMode: false,
        },
      },
    ],
  };

  const generateImages = () => {
    let toReturn = [];
    for (let i = 0; i < 10; i++) {
      toReturn.push(
        <div>
          <img
            className='to-slide'
            src={`/assets/images/rules-images/rule-${i + 1}.jpg`}
            alt={`rule-${i + 1}`}
            onClick={() => {
              onSmallImgClick(i + 1);
            }}
          />
        </div>
      );
    }
    return toReturn;
  };

  const onSmallImgClick = idx => {
    setIndice(idx);
    setShowModal(true);
  };

  return (
    <div className='rules-page'>
      <div className='content'>
        <img
          src={process.env.PUBLIC_URL + "/assets/images/gos.png"}
          className='logo-title'
          alt='hey'
        />
        <div className='nav-buttons'>
          <WaveButton path={routes.homepage}>Nominés</WaveButton>
          <WaveButton path={routes.participants}>Participants</WaveButton>
        </div>
        <span className='intro-sentence'> - Les Règles du jeu - </span>
        <div className='content-blocks'>
          <div className='caroussel-block'>
            <div className='slider-container'>
              <Slider {...sliderSettings}>{generateImages()}</Slider>
              {showModal && (
                <Modal onClick={e => setShowModal(false)}>
                  <img
                    className='image-on-modal'
                    src={`/assets/images/rules-images/rule-${indice}.jpg`}
                    alt={`rule-${indice}`}
                  />
                </Modal>
              )}
            </div>
            <div className='fake-blur'></div>
          </div>
          <div className='dice'>
            <img
              src={process.env.PUBLIC_URL + "/assets/images/des_brillants.png"}
              alt='dés brillants'
            />
            <span className='start-text'>La partie peut commencer...</span>
          </div>
        </div>
        <div className='footer-container'>
          <Footer />
        </div>
      </div>
    </div>
  );
};

export default RulesPage;
