import React, { useEffect, useRef, useState } from "react";
import * as _ from "lodash";
import {
  BackgroundNumber,
  BackgroundVoted,
  CardContainer,
  CardTitle,
  CrossImg,
  FlipCardContainer,
  FlipCardInner,
  HintsCard,
  QuestionMark,
  SecretCardContainer,
  SecretCardMini,
  SecretCardMiniContent,
  SecretCardModalBackFace,
  SecretCardModalContent,
  TitleImage,
  VoteContainer,
  VoteForm,
  VoteLabel,
  VoteSelect,
  VoteSelectOption,
  VoteSubmitButton,
} from "./SecretCard.style";
import crossWhiteImg from "./assets/card-cross-border_white.png";
import crossBlueImg from "./assets/card-cross-border_blue.png";
import gosTitleImg from "./assets/title-image.png";
import Modal from "../../UICs/Modal/Modal.uic";
import { ApiCall } from "../../../services/apiCall";
import { SvgSpinners3DotsMove } from "../../loading/Loading";
import { useAuth } from "../../../auth/AuthContext";

const SecretCard = ({
  idNumber,
  title,
  hints = "",
  participantVoted,
  participantsList,
  refreshData,
  width = "100%",
  height = "100%",
}) => {
  const [miniCardContentDimension, setMiniCardContentDimension] = useState({
    width: 0,
    height: 0,
  });
  const { toast, Bounce } = require("react-toastify");

  const { user } = useAuth();

  const [containerHeight, setContainerHeight] = useState(0);

  const [showModal, setShowModal] = useState(false);

  const [isVotabled, setIsVotabled] = useState(false);

  const [loading, setLoading] = useState(false);

  const [personVoted, setPersonVoted] = useState("");

  const [messageVariant, setMessageVariant] = useState("");

  const miniCardRef = useRef();

  useEffect(() => {
    setContainerHeight(miniCardRef.current.clientWidth * 1.320121951219512);
  }, [miniCardRef]);

  useEffect(() => {
    let contentWidth = 0;
    let contentHeight = 0;
    const ratio = {
      width: 1.084935064935065,
      height: 1.055885167464115,
    };
    contentWidth = miniCardRef.current.clientWidth / ratio.width;
    contentHeight = miniCardRef.current.clientHeight / ratio.height;
    setMiniCardContentDimension({ width: contentWidth, height: contentHeight });
  }, [containerHeight]);

  const handleVote = () => {
    setLoading(true);

    if (idNumber && personVoted && user.id) {
      const handleVoted$ = ApiCall.postVoted(
        `/participants/nomines/${user.id}/vote`,
        {
          numeroID: parseInt(idNumber),
          selectedParticipantId: parseInt(personVoted),
        }
      );

      let voted = handleVoted$.subscribe({
        next: response => {
          setLoading(false);
          toast.success(response?.data?.message, {
            transition: Bounce,
          });
          refreshData(new Date());
        },
        error: err => {
          toast.error(err?.response?.data?.message, {
            transition: Bounce,
          });
          setMessageVariant("error");
          setLoading(false);
        },
        complete: () => {},
      });

      return () => {
        voted.unsubscribe();
        setLoading(false);
      };
    }
  };

  return (
    <SecretCardContainer
      width={width}
      height={height !== "100%" ? height : `${containerHeight}px`}
    >
      <SecretCardMini
        ref={miniCardRef}
        width={miniCardContentDimension.width}
        height={miniCardContentDimension.height}
      >
        <SecretCardMiniContent
          width={miniCardContentDimension.width}
          height={miniCardContentDimension.height}
          onClick={() => setShowModal(true)}
        >
          <span>{idNumber || "?"}</span>
          <div>
            <button onClick={() => setShowModal(true)}>
              Retourner
              {/* <Lottie style={{height:'90%', width:'auto'}} options={defaultOptions} speed="0.2" /> */}
            </button>
          </div>
        </SecretCardMiniContent>
      </SecretCardMini>
      {showModal && (
        <Modal onClick={e => setShowModal(false)}>
          <FlipCardContainer>
            <FlipCardInner>
              <SecretCardModalBackFace>
                <CrossImg src={crossBlueImg} alt='White crosses' />
                <CardContainer inverted={true}>
                  <QuestionMark>?</QuestionMark>
                </CardContainer>
              </SecretCardModalBackFace>

              <SecretCardModalContent
                onClick={e => {
                  e.stopPropagation();
                }}
              >
                <BackgroundNumber>{idNumber}</BackgroundNumber>
                {participantVoted && <BackgroundVoted>Voté</BackgroundVoted>}
                <CrossImg src={crossWhiteImg} alt='White crosses' />
                <CardContainer>
                  <TitleImage src={gosTitleImg} alt='Title img' />
                  <CardTitle>{title}</CardTitle>
                  <HintsCard>
                    <React.Fragment>
                      <span>{hints}</span>
                    </React.Fragment>
                  </HintsCard>
                </CardContainer>
                <VoteForm>
                  {participantVoted && (
                    <div className='person'>
                      <label htmlFor='personId'>Personne votée :</label>{" "}
                      <span id='personId'>{`${participantVoted.firstname} ${participantVoted.lastname}`}</span>
                    </div>
                  )}
                  <VoteLabel>Faire un vote</VoteLabel>
                  <VoteContainer>
                    <VoteSelect
                      variant={messageVariant}
                      placeholder='Selectionner'
                      defaultValue=''
                      onChange={e => {
                        setPersonVoted(e.target.value);
                        setIsVotabled(e.target.value ? true : false);
                      }}
                    >
                      <VoteSelectOption value='' disabled>
                        Sélectionner une personne
                      </VoteSelectOption>
                      {_.orderBy(participantsList, ["firstname"], ["asc"])?.map(
                        (participant, index) => (
                          <VoteSelectOption key={index} value={participant.id}>
                            {`${participant.firstname} ${participant.lastname}`}
                          </VoteSelectOption>
                        )
                      )}
                    </VoteSelect>
                    <VoteSubmitButton
                      type='button'
                      disabled={!isVotabled || loading}
                      onClick={handleVote}
                    >
                      {loading ? <SvgSpinners3DotsMove /> : "Voter"}
                    </VoteSubmitButton>
                  </VoteContainer>
                </VoteForm>
              </SecretCardModalContent>
            </FlipCardInner>
          </FlipCardContainer>
        </Modal>
      )}
    </SecretCardContainer>
  );
};

export default SecretCard;
