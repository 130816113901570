import styled, { css, keyframes } from "styled-components";

const secretCardBorderRadiusRatio = 12.83333333333333;
const spanFontSizeRatio = 2.548780487804878;
const spanTranslateYRatio = 5.5;
const buttomBottomPositionRatio = 13.0625;
const buttonHeightRatio = 5.5;
const buttonWidthRatio = 1.4;
const buttonFontSizeRatio = 13.0625;
const buttonBorderRadiusRatio = 30.8;
const ratio = {
  width: 1.084935064935065,
  height: 1.055885167464115,
};

const flipCard = keyframes`
  0%{
    transform: scale(0.75) rotateY(0deg);
  }
  100%{
    transform: scale(0.75) rotateY(180deg);
  }
`;
const flipCardAnimation = css`
  ${flipCard} 800ms normal ease-in-out both;
`;

const arise = keyframes`
  0%{
    opacity: 0;
  }
  100%{
    opacity: 1;
  }
`;
const ariseAnimation = css`
  ${arise} 600ms ease-in-out normal both;
`;

export const SecretCardContainer = styled.div`
  position: relative;
  width: ${({ width }) => width};
  height: ${({ height }) => height};
`;

export const SecretCardMini = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;
  background: linear-gradient(to top, #646464, #cecece);
  border-radius: ${({ width }) => width / secretCardBorderRadiusRatio}px;
  cursor: pointer;

  &:hover {
    & div {
      & > span {
        transform: translateY(
          ${({ height }) => -height / spanTranslateYRatio}px
        );
      }
      & > div {
        opacity: 1;
      }
    }
  }
`;

export const SecretCardMiniContent = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;
  transform: scale(${1 / ratio.width}, ${1 / ratio.height});
  background-color: #d4166d;
  border-radius: ${({ width }) => width / secretCardBorderRadiusRatio}px;

  & > span {
    font-size: ${({ height }) => height / spanFontSizeRatio}px;
    font-weight: bold;
    color: white;
    transform: translate(0);
    transition: transform 200ms ease-in-out;
    font-family: "Raleway", sans-serif;

    /* &:hover {
      transform: translateY(${({ height }) => -height / spanTranslateYRatio}px);
    } */
  }

  & > div {
    position: absolute;
    bottom: ${({ height }) => height / buttomBottomPositionRatio}px;
    display: flex;
    justify-content: center;
    width: 100%;
    height: ${({ height }) => height / buttonHeightRatio}px;
    background-color: transparent;
    opacity: 0;
    transition: opacity 200ms ease-in-out;

    & > button {
      position: relative;
      display: flex;
      justify-content: center;
      align-items: center;
      width: ${({ width }) => width / buttonWidthRatio}px;
      height: 100%;
      background-color: white;
      color: #d4166d;
      border: 0;
      border-radius: ${({ width }) => width / buttonBorderRadiusRatio}px;
      font-size: ${({ height }) => height / buttonFontSizeRatio}px;
      font-family: "Raleway", sans-serif;
      cursor: pointer;
      z-index: 0;

      &::after {
        content: "";
        position: absolute;
        top: 0;
        bottom: 0;
        left: 0;
        right: 0;
        border-radius: ${({ width }) => width / buttonBorderRadiusRatio}px;
        background-color: #d4166d;
        opacity: 0;
        transition: opacity 150ms ease-in-out;
        z-index: -1;
      }

      &:hover {
        &::after {
          opacity: 0.125;
        }
      }
    }
  }
`;

export const FlipCardContainer = styled.div`
  background-color: transparent;
  width: 490px;
  height: 730px;
  min-height: 730px;
  box-sizing: border-box;
  perspective: 1500px;
  animation: ${ariseAnimation};
  animation-delay: 600ms;
`;

export const FlipCardInner = styled.div`
  position: relative;
  width: 100%;
  height: 100%;
  text-align: center;
  /* transition: transform 0.8s; */
  transform-style: preserve-3d;
  animation: ${flipCardAnimation};
  animation-delay: 1250ms;
`;

export const SecretCardModalContent = styled.div`
  position: relative;
  display: flex;
  width: 100%;
  height: 100%;
  background-color: black;
  cursor: default;
  border: 5px solid white;
  border-radius: 10px;
  padding: 40px;
  box-sizing: border-box;
  /* transform: scale(0.75); */
  transform: rotateY(180deg);
  z-index: 0;
  backface-visibility: hidden;
`;

export const CrossImg = styled.img`
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  width: 100%;
  height: 100%;
  object-fit: fill;
  z-index: -1;
`;

export const CardContainer = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  box-sizing: border-box;
  width: 100%;
  padding: 10px;
  background-color: ${props => (props.inverted ? "#d4166d" : "white")};
  z-index: 0;

  &::after {
    content: "";
    position: absolute;
    top: 4px;
    bottom: 4px;
    left: 4px;
    right: 4px;
    border: 4px solid ${props => (!props.inverted ? "#d4166d" : "white")};
    z-index: -1;
  }
`;

export const BackgroundNumber = styled.span`
  position: absolute;
  bottom: 50px;
  right: 20px;
  color: #808080;
  line-height: 1;
  font-family: "Raleway", sans-serif;
  font-size: 20rem;
  font-weight: bold;
  opacity: 0.15;
  z-index: 1;
`;

export const BackgroundVoted = styled.span`
  position: absolute;
  top: 21%;
  left: 50%;
  transform: translate(-50%, -50%);
  rotate: -45deg;
  transform-origin: center;
  color: green;
  line-height: 1;
  font-family: "Raleway", sans-serif;
  font-size: 8rem;
  font-weight: 900;
  opacity: 0.15;
  z-index: 1;
`;

export const TitleImage = styled.img`
  margin-top: 6px;
  object-fit: contain;
  height: 60px;
  opacity: 0.9525;
`;

export const CardTitle = styled.span`
  text-align: center;
  display: flex;
  align-items: center;
  min-height: 125px;
  margin-top: 40px;
  padding: 0 4px;
  color: #d4166d;
  text-transform: uppercase;
  line-height: 1.32;
  font-weight: bold;
  font-size: 1.2rem;
  font-family: "Raleway", sans-serif;
`;

export const HintsCard = styled.div`
  display: flex;
  flex-direction: column;
  margin-top: 10px;
  width: 100%;

  & > span {
    text-align: center;
    line-height: 1.3;
    font-size: 1.3rem;
    padding: 10px 0;
    font-family: "PT Sans", sans-serif;
    opacity: 0.85;
  }

  & > hr {
    margin: 0;
    border: 0;
    border-bottom: 1px solid #d4166d;
    opacity: 0.6;
  }
`;

export const SecretCardModalBackFace = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  display: flex;
  height: 100%;
  width: 100%;
  background-color: white;
  backface-visibility: hidden;
  border: 5px solid #d4166d;
  border-radius: 10px;
  padding: 40px;
  box-sizing: border-box;
  z-index: 0;
  cursor: default;

  & > div {
    justify-content: center;
  }
`;

export const QuestionMark = styled.span`
  line-height: 1.3;
  font-size: 27rem;
  font-weight: bold;
  color: white;
  font-family: "PT Sans", sans-serif;
`;

// Formulaire de vote
export const VoteForm = styled.form`
  position: absolute;
  left: 50%;
  bottom: 45px;
  transform: translateX(-50%);
  width: calc(100% - 110px);
  padding: 10px 8px;
  display: flex;
  flex-direction: column;
  gap: 7px;
  z-index: 100;

  .person {
    width: 100%;
    background-color: #00800034;
    padding: 10px;
    border-radius: 0.25rem;
    font-size: 1.1285rem;
    span {
      font-weight: bold;
    }
  }
`;

export const VoteLabel = styled.label`
  width: 100%;
  text-align: left;
  font-weight: 600;
  font-size: 1.1rem;
`;

export const VoteContainer = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  gap: 10px;
`;

export const VoteSelect = styled.select`
  flex: 1;
  width: 100%;
  text-align: left;
  height: 48px;
  cursor: pointer;
  padding: 0px 15px;
  background-color: #ffffff;
  font-size: 1.0525rem;
  border: 3px solid #3f51b5;
  box-shadow: 0px 0px 20px rgba($color: #000000, $alpha: 0.5);
`;

export const VoteSelectOption = styled.option`
  flex: 1;
  width: 100%;
  text-align: left;
  font-size: 0.8rem;
`;

export const VoteSubmitButton = styled.button`
  height: 48px;
  padding: 0 15px;
  font-size: 1rem;
  background-color: #3f51b5; /* #d4166d; */
  border: none;
  font-weight: 600;
  color: #ffffff;
  cursor: pointer;

  &:disabled {
    border: 1px solid #999999;
    background-color: #cccccc;
    color: #666666;
    cursor: none;
  }
`;
