import React, { createContext, useContext, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { routes } from '../config/routes';
import { Bounce, toast } from 'react-toastify';

const AuthContext = createContext();

export const AuthProvider = ({ children }) => {
  const [user, setUser] = useState(() => {
    // Charger l'utilisateur depuis le localStorage au démarrage
    const savedUser = localStorage.getItem('user');
    return savedUser ? JSON.parse(savedUser) : null;
  });
  const history = useHistory();

  // Fonction de connexion
  const login = async (phoneNumber, password) => {
    try {
      const response = await fetch('https://api-sg.everest-consulting.org/v1/participants/login', {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({ phone_number: phoneNumber, password: password})
      });

      const data = await response.json();

      if (!data.error) {
        const userData = { id: data?.data.participantId_connecte, status: data?.data.status, isConnect: data?.data.isConnect };
        setUser(userData);
        
        // Stocker les informations utilisateur dans le localStorage
        localStorage.setItem('user', JSON.stringify(userData));
        history.push(routes.homepage);
        toast.success("Soyez les bienvenues,🎲 le jeu peut commencer 🏁", {
          transition: Bounce,
        });
      } else {
        toast.error("Échec de la connexion. Vérifiez votre numéro.", {
          transition: Bounce,
        });
        // alert('Échec de la connexion. Vérifiez votre numéro.');
      }
    } catch (error) {
      toast.error(`Erreur lors de la connexion: ${error}`, {
        transition: Bounce,
      });
      // alert('Erreur lors de la connexion:', error);
    }
  };

  // Fonction de déconnexion
  const logout = async (id) => {
    
    try {
      const res = await fetch(`https://api-sg.everest-consulting.org/v1/participants/logout/${id}`, {
        method: 'GET',
        headers: { 'Content-Type': 'application/json' },
      });
      
      if (!res.error) {
        setUser(null);
        localStorage.removeItem('user'); // Supprimer l'utilisateur du localStorage
        history.push(routes.loginpage);
  
        setTimeout(() => {
          toast.info("Vous êtes maintenant déconnectés !", {
            transition: Bounce,
          });
          // alert('Vous êtes maintenant déconnectés !');
        }, 200);

      } else{
        toast.error("Échec de la déconnexion.", {
          transition: Bounce,
        });
        // alert('Échec de la déconnexion.');
      }
    } catch (error) {
      toast.error(`Erreur lors de la déconnexion: ${error}`, {
        transition: Bounce,
      });
      // alert('Erreur lors de la connexion:', error);
    }

  };

  return (
    <AuthContext.Provider value={{ user, login, logout }}>
      {children}
    </AuthContext.Provider>
  );
};

export const useAuth = () => useContext(AuthContext);