import { useEffect, useState } from "react";
import * as _ from "lodash";
import Lottie from "react-lottie";
import eye from "../../assets/lottie-json/eye.json";
import SecretCard from "../../components/layouts/SecretCard/SecretCard.layout";
import WaveButton from "../../components/UICs/WaveButton/WaveButton.uic.jsx";
import { routes } from "../../config/routes";
import { ApiCall } from "../../services/apiCall";
import "./HomePage.scss";
import Footer from "../../components/layouts/Footer/Footer.jsx";
import NomineEmpty from "../../components/layouts/Empty/NomineEmpty.jsx";
import { SvgSpinners6DotsScale } from "../../components/loading/LoadingSpinner.jsx";
import { useAuth } from "../../auth/AuthContext.js";

const HomePage = () => {
  const [nomines, setNomines] = useState([]);
  const [voteList, setVoteList] = useState([]);
  const [refreshData, setRefreshData] = useState();

  const [isloading, setIsloading] = useState(false);

  const [participants, setParticipants] = useState([]);

  const { user } = useAuth();

  /**
   * LISTE DES PARTICIPANTS A SELECTIONNER
   */
  useEffect(() => {
    const participants$ = ApiCall.getParticipants("/participants");

    let subcription = participants$.subscribe({
      next: response => {
        setParticipants(response?.data?.data);
      },
      error: err => {},
      complete: () => {},
    });
    handleStatus();
    return () => {
      subcription.unsubscribe();
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  /**
   * LISTE DES PERSONNES QUE J'AI VOTE SUR LES CARTES
   */
  useEffect(() => {
    getVoteList();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [refreshData]);

  /**
   * LISTE DES NOMINES DE LA JOURNEE
   */
  useEffect(() => {
    setIsloading(true);
    const nomines$ = ApiCall.post("/participants/list-nomine");

    let subcription = nomines$.subscribe({
      next: response => {
        response?.data?.data.forEach(item => {
          const participantVoted = voteList.find(
            p => p.numeroID === item.numeroIdentite
          );
          if (participantVoted) {
            item.selectedParticipant = participantVoted.selectedParticipant;
          }
        });
        setNomines(response?.data.data);
        setIsloading(false);
        handleStatus();
      },
      error: err => {
        setIsloading(false);
      },
      complete: () => {},
    });

    return () => {
      subcription.unsubscribe();
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [voteList]);

  /**
   * RECHARGE DE LA LISTE DES NOMINES DE LA JOURNEE
   */
  const loadNomines = () => {
    getVoteList();
    setIsloading(true);

    const nomines$ = ApiCall.post("/participants/list-nomine");

    setNomines([]);

    let subcription;

    if (voteList.length > 0) {
      subcription = nomines$.subscribe({
        next: response => {
          response?.data?.data.forEach(item => {
            const participantVoted = voteList.find(
              p => p.numeroID === item.numeroIdentite
            );
            if (participantVoted) {
              item.selectedParticipant = participantVoted.selectedParticipant;
            }
          });
          setNomines(response?.data?.data);
          setIsloading(false);
          handleStatus();
        },
        error: err => {
          setIsloading(false);
        },
        complete: () => {
          subcription.unsubscribe();
        },
      });
    }
  };

  const handleStatus = () => {
    const userLastData = _.find(participants, function (o) {
      if (o.id === user?.id) {
        return {
          id: o.id,
          status: o.status,
          isConnect: o.isConnect,
        };
      }
    });

    if (userLastData) {
      localStorage.setItem("user", JSON.stringify(userLastData));
    }
  };

  const getVoteList = () => {
    const voteList$ = ApiCall.post(
      `/participants/votes/FrontOffice/${user.id}`
    );

    let subcription = voteList$.subscribe({
      next: response => {
        setVoteList(response?.data.votes);
        setIsloading(false);
        handleStatus();
      },
      error: err => {
        setIsloading(false);
      },
      complete: () => {
        subcription.unsubscribe();
      },
    });
  };

  const defaultOptions = {
    loop: true,
    autoplay: true,
    animationData: eye,
    rendererSettings: {
      preserveAspectRatio: "xMidYMid slice",
    },
  };

  return (
    <div className='home'>
      <div className='home-content'>
        <div>
          <img
            src={process.env.PUBLIC_URL + "/assets/images/gos.png"}
            className='logo-title'
            alt='hey'
          />
          <div className='nav-buttons'>
            <WaveButton path={routes.rulespage}>Règles du jeu</WaveButton>
            <WaveButton click={loadNomines}> Recharger </WaveButton>
            <WaveButton path={routes.participants}>Participants</WaveButton>
          </div>
          <span className='intro-sentence'>- Les nominés de la semaine -</span>
          <div className='hints-block'>
            <div className='hints-container'>
              {!isloading ? (
                nomines[0]?.nomines.length > 0 ? (
                  nomines?.map((hint, idx) => {
                    if (hint?.nomines?.length > 0) {
                      return (
                        <div className='hint-box' key={`hint-${idx}`}>
                          <div className='hint-card'>
                            <SecretCard
                              title={hint.secret}
                              hints={hint.nomines[0]?.indice}
                              idNumber={hint?.numeroIdentite}
                              participantVoted={hint?.selectedParticipant}
                              participantsList={participants}
                              refreshData={e => {
                                setRefreshData(e);
                              }}
                            />
                          </div>
                        </div>
                      );
                    }
                    return null;
                  })
                ) : (
                  <NomineEmpty title="Il n'y a pas de nominés actuellement." />
                )
              ) : (
                <div className='loading-container'>
                  <SvgSpinners6DotsScale />
                  <span>Chargement en cours...</span>
                </div>
              )}

              {/* {isloading && <span>Chargement en cours...</span>} */}
            </div>
            <div className='fake-blur'></div>
            <div className='eye-container'>
              <Lottie options={defaultOptions} speed={0.2} />
            </div>
          </div>
        </div>
        <div className='footer-container'>
          <Footer />
        </div>
      </div>
    </div>
  );
};

export default HomePage;
