import styled, { css, keyframes } from "styled-components";

const showModal = keyframes`
    0%{
        opacity: 0;
        transform: scale(0);
    }
    100%{
        opacity: 1;
        transform: scale(1);
    }
`;
const showModalAnimation = css`
  ${showModal} ease-in-out 600ms normal both;
`;

export const ModalStyle = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  background-color: rgba(0, 0, 0, 0.6);
  cursor: zoom-out;
  opacity: 0;
  transform: scale(0);
  animation: ${showModalAnimation};
  overflow: auto;
  z-index: 1000;
`;

export const ModalWrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  min-height: 100%;
  width: 100%;
  overflow: auto;
`;
