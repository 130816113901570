import { useEffect, useState } from "react";
import * as _ from "lodash";
import { ParticipantsCard } from "../../components/layouts/ParticipantsCard/ParticipantsCard.layout";
import WaveButton from "../../components/UICs/WaveButton/WaveButton.uic";
import { routes } from "../../config/routes";
import { ApiCall } from "../../services/apiCall";
import "./ParticipantsPage.scss";
import Footer from "../../components/layouts/Footer/Footer";
import { useAuth } from "../../auth/AuthContext";
import NomineEmpty from "../../components/layouts/Empty/NomineEmpty";
import { SvgSpinners6DotsScale } from "../../components/loading/LoadingSpinner";

const ParticipantsPage = () => {
  const [participants, setParticipants] = useState([]);

  const [isloading, setIsloading] = useState(false);
  const [userAvatar, setUserAvatar] = useState("");

  const { user } = useAuth();

  useEffect(() => {
    setUserAvatar(
      `https://picsum.photos/id/${Math.round(Math.random() * 100) + 1}/300`
    );
    setIsloading(true);
    const participants$ = ApiCall.getParticipants("/participants");

    let subcription = participants$.subscribe({
      next: response => {
        if (response?.data?.data && response?.data?.data.length) {
          setParticipants(response?.data?.data);
          handleStatus();
          setIsloading(false);
        }
      },
      error: err => {
        setIsloading(false);
      },
      complete: () => {},
    });
    handleStatus();
    return () => {
      subcription.unsubscribe();
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const loadParticipants = () => {
    setIsloading(true);
    const participants$ = ApiCall.getParticipants("/participants");

    setParticipants([]);
    setTimeout(() => {
      let subcription = participants$.subscribe({
        next: response => {
          setParticipants(response?.data?.data);
          setIsloading(false);
          handleStatus();
        },
        error: err => {
          setIsloading(false);
        },
        complete: () => {
          subcription.unsubscribe();
        },
      });
    }, 1000);
  };

  const handleStatus = () => {
    const userLastData = _.find(participants, function (o) {
      if (o.id === user?.id) {
        return {
          id: o.id,
          status: o.status,
          isConnect: o.isConnect,
        };
      }
    });

    if (userLastData) {
      localStorage.setItem("user", JSON.stringify(userLastData));
    }
  };

  // const generateFalseParticipants = () => {
  //   let toReturn = [];
  //   for (let i = 0; i < 20; i++) {
  //     let falseStatut = Math.round(Math.random() + 0.25) === 1 ? true : false;
  //     toReturn.push({
  //       name: `Nom ${i + 1}`,
  //       pseudo: `Pseudo ${i + 1}`,
  //       statut: true,
  //       avatar2: `https://picsum.photos/id/${
  //         Math.round(Math.random() * 100) + 1
  //       }/300`,
  //       avatar: `http://graph.facebook.com/v2.5/${40}/picture?width=300&height=300`,
  //       secret: "Je suis le secret de ce joueur. I'm discovered !",
  //     });
  //   }
  //   return toReturn;
  // };

  console.log("participants: ", participants);

  return (
    <div className='participants-page'>
      <div className='content'>
        <div>
          <img
            src={process.env.PUBLIC_URL + "/assets/images/gos.png"}
            className='logo-title'
            alt='hey'
          />
          <div className='nav-buttons'>
            <WaveButton path={routes.homepage}>Nominés</WaveButton>
            <WaveButton click={loadParticipants}> Recharger </WaveButton>
            <WaveButton path={routes.rulespage}>Règles du jeu</WaveButton>
          </div>
          <span className='intro-sentence'> - Les participants - </span>
          <div className='participants-block'>
            <div className='participants-container'>
              {!isloading ? (
                participants.length > 0 ? (
                  participants.map((participant, idx) => {
                    return (
                      <div
                        className='participant-box'
                        key={`participant-${idx}`}
                      >
                        <ParticipantsCard
                          avatar={participant.photo_url ?? userAvatar}
                          name={`${participant.firstname} ${participant.lastname}`}
                          points={participant.points}
                          secret={participant.secret}
                          statut={participant.status}
                        />
                      </div>
                    );
                  })
                ) : (
                  <NomineEmpty title="Il n'y a pas de nominés actuellement." />
                )
              ) : (
                <div className='loading-container'>
                  <SvgSpinners6DotsScale />
                  <span>Chargement en cours...</span>
                </div>
              )}
            </div>
            <div className='fake-blur'></div>
          </div>
        </div>
        <div className='footer-container'>
          <Footer />
        </div>
      </div>
    </div>
  );
};

export default ParticipantsPage;
