import { useAuth } from "../../../auth/AuthContext";
import { PowerIcon } from "../../powerIcon/PowerIcon";
import "./Footer.style.scss";

const Footer = () => {
  const { user, logout } = useAuth();
  return (
    <div className='footer-content'>
      <button className='logout-btn' onClick={() => logout(user.id)}>
        <PowerIcon />
      </button>
      <span className='dec_text'> Déconnexion </span>
    </div>
  );
};

export default Footer;
