import { ModalStyle, ModalWrapper } from "./Modal.styles";

const Modal = ({ children, ...otherProps }) => {
  return (
    <ModalStyle {...otherProps}>
      <ModalWrapper>{children}</ModalWrapper>
    </ModalStyle>
  );
};

export default Modal;
